import React from "react";
import Flex from "app/components/Flex";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import queryString from "query-string";
import { ProgressBar } from "./styles";

interface RootState {
  components: {
    Register: {
      step: string;
      steps: string[];
    };
  };
}

export function ProgressTrackComponent() {
  const { steps: registerSteps } = useSelector(
    ({ components }: RootState) => components.Register
  );

  const location = useLocation();
  const totalStepCount = registerSteps.length;

  const progressBarPercentage = (function calculateProgressBarPercentage() {
    return Math.floor(
      ((Number(queryString.parse(location.search).step) + 1) / totalStepCount) *
        100
    );
  })();

  return (
    <Flex
      position={{ _: "", lg: "absolute" }}
      height={{ _: "4px", lg: "460px" }}
      width={{ _: "100%", lg: "4px" }}
      left={{ _: "", lg: "-2px" }}
    >
      <Flex height="100%" bg="monochrome.1" width="100%">
        <ProgressBar
          height={{ _: "", lg: `${progressBarPercentage}%` }}
          width={{ _: `${progressBarPercentage}%`, lg: "" }}
          color="blue"
        />
      </Flex>
    </Flex>
  );
}
